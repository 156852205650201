import React from "react";
import PropTypes from "prop-types";
import Layout from "../components/Layout/Layout";
import PrivacyPolicy from "../containers/PrivacyPolicy/PrivacyPolicy";

const Privacy = ({ pageContext }) => (
	<Layout pageContext={pageContext}>
		<PrivacyPolicy />
	</Layout>
);

Privacy.propTypes = {
	pageContext: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string]))
		.isRequired,
};

export default Privacy;
