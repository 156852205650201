import React, { useEffect } from "react";
import useTranslations from "../../hoc/useTranslations";
import { PAGE } from "../../helpers/constants";
import { scrollToTop } from "../../helpers/helpers";
import Description from "../../components/Description/Description";
import Heading from "../../components/Heading/Heading";
import SEO from "../../components/seo";

import "./PrivacyPolicy.scss";

const PrivacyPolicy = () => {
	const { privacy } = useTranslations();

	useEffect(() => {
		scrollToTop();
	}, []);

	return (
		<>
			<SEO papage={PAGE.REPORT} />
			<section className="section section__privacy">
				{privacy.map(({ title, descriptions }) => (
					<article key={title} className="privacy">
						<Heading className="privacy__heading" type={2}>
							{title}
						</Heading>
						{descriptions.map(description => (
							<Description key={description} className="privacy__description">
								{description}
							</Description>
						))}
					</article>
				))}
			</section>
		</>
	);
};

export default PrivacyPolicy;
